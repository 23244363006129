import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use(VueRouter)



const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: {
      title: 'index',
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'login',
    },
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/personalDes',
    name: 'personalDes',
    meta: {
      title: 'personalDes',
    },
    component: () => import('@/views/systemSet/personalDes.vue'),
  },
  {
    path: '/modifyPwd',
    name: 'modifyPwd',
    meta: {
      title: 'modifyPwd',
    },
    component: () => import('@/views/systemSet/modifyPwd.vue'),
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    meta: {
      title: 'forgetPwd',
    },
    component: () => import('@/views/systemSet/forgetPwd.vue'),
  },
  {
    path: '/enroll',
    name: 'enroll',
    meta: {
      title: 'enroll',
    },
    component: () => import('@/views/matchesCenter/enroll.vue'),
  },
  {
    path: '/bigScreen',
    name: 'bigScreen',
    meta: {
      title: 'bigScreen',
    },
    component: () => import('@/views/competitionCenter/bigScreen.vue'),
  },
  {
    path: '/competitionList',
    name: 'competitionList',
    meta: {
      title: 'competitionList',
    },
    component: () => import('@/views/competitionCenter/competitionList.vue'),
  },
  {
    path: '/competitionDetail',
    name: 'competitionDetail',
    meta: {
      title: 'competitionDetail',
    },
    component: () => import('@/views/competitionCenter/competitionDetail.vue'),
  },
  {
    path: '/competitionDetailByGroup',
    name: 'competitionDetailByGroup',
    meta: {
      title: 'competitionDetailByGroup',
    },
    component: () => import('@/views/competitionCenter/competitionDetailByGroup.vue'),
  },
  {
    path: '/competitionStart',
    name: 'competitionStart',
    meta: {
      title: 'competitionStart',
    },
    component: () => import('@/views/competitionCenter/competitionStart.vue'),
  },
  {
    path: '/systemSet',
    name: 'systemSet',
    meta: {
      title: 'systemSet',
    },
    component: () => import('@/views/systemSet.vue'),
    children: [
      //题库管理
      {
        path: '/exambankManage',
        name: 'exambankManage',
        meta: {
          title: 'exambankManage',
        },
        component: () => import('@/views/examManage/exambankManage.vue'),
      },
      //题库管理中的新建赛题
      {
        path: '/addExam',
        name: 'addExam',
        meta: {
          title: 'addExam',
        },
        component: () => import('@/views/examManage/components/addExam.vue')
      },
      //题库管理中的查看赛题
      {
        path: '/watchExam',
        name: 'watchExam',
        meta: {
          title: 'watchExam',
        },
        component: () => import('@/views/examManage/components/watchExam.vue')
      },
      //题库管理 编辑试题
      {
        path: '/editExam',
        name: 'editExam',
        meta: {
          title: 'editExam',
        },
        component: () => import('@/views/examManage/components/editExam.vue')
      },
      // 题库管理中，新建多选题
      {
        path: '/addMultipleExam',
        name: 'addMultipleExam',
        meta: {
          title: 'addMultipleExam',
        },
        component: () => import('@/views/examManage/components/addMultipleExam.vue')
      },
      // 编辑多选题
      {
        path: '/editMultipleExam',
        name: 'editMultipleExam',
        meta: {
          title: 'editMultipleExam',
        },
        component: () => import('@/views/examManage/components/editMultipleExam.vue')
      },
      //题库管理中的查看多选
      {
        path: '/watchMultipleExam',
        name: 'watchMultipleExam',
        meta: {
          title: 'watchMultipleExam',
        },
        component: () => import('@/views/examManage/components/watchMultipleExam.vue')
      },
      // 题库管理中，新建单选题
      {
        path: '/addSingleExam',
        name: 'addSingleExam',
        meta: {
          title: 'addSingleExam',
        },
        component: () => import('@/views/examManage/components/addSingleExam.vue')
      },
      // 编辑单选题
      {
        path: '/editSingleExam',
        name: 'editSingleExam',
        meta: {
          title: 'editSingleExam',
        },
        component: () => import('@/views/examManage/components/editSingleExam.vue')
      },
      //题库管理中的查看单选
      {
        path: '/watchSingleExam',
        name: 'watchSingleExam',
        meta: {
          title: 'watchSingleExam',
        },
        component: () => import('@/views/examManage/components/watchSingleExam.vue')
      },
      // 题库管理中，新建填空题
      {
        path: '/addBlankExam',
        name: 'addBlankExam',
        meta: {
          title: 'addBlankExam',
        },
        component: () => import('@/views/examManage/components/addBlankExam.vue')
      },
      // 题库管理中，编辑填空题
      {
        path: '/editBlankExam',
        name: 'editBlankExam',
        meta: {
          title: 'editBlankExam',
        },
        component: () => import('@/views/examManage/components/editBlankExam.vue')
      },
      // 题库管理中，查看填空题
      {
        path: '/watchBlankExam',
        name: 'watchBlankExam',
        meta: {
          title: 'watchBlankExam',
        },
        component: () => import('@/views/examManage/components/watchBlankExam.vue')
      },
      // 题库管理中，新建推理题
      {
        path: '/addinFerenceExam',
        name: 'addinFerenceExam',
        meta: {
          title: 'addinFerenceExam',
        },
        component: () => import('@/views/examManage/components/addinFerenceExam.vue')
      },
      // 题库管理中，编辑推理题
      {
        path: '/editFerenceExam',
        name: 'editFerenceExam',
        meta: {
          title: 'editFerenceExam',
        },
        component: () => import('@/views/examManage/components/editFerenceExam.vue')
      },
      // 题库管理中，查看推理题
      {
        path: '/watchinFerenceExam',
        name: 'watchinFerenceExam',
        meta: {
          title: 'watchinFerenceExam',
        },
        component: () => import('@/views/examManage/components/watchinFerenceExam.vue')
      },
      //试卷管理
      {
        path: '/paperManage',
        name: 'paperManage',
        meta: {
          title: 'paperManage',
        },
        component: () => import('@/views/paperManage/paperManage.vue'),
      },
      //新增试卷
      {
        path: '/addPaper',
        name: 'addPaper',
        meta: {
          title: 'addPaper',
        },
        component: () => import('@/views/paperManage/addPaper.vue'),
      },
      //编辑试卷
      {
        path: '/editPaper',
        name: 'editPaper',
        meta: {
          title: 'editPaper',
        },
        component: () => import('@/views/paperManage/editPaper.vue'),
      },
      //比赛管理
      {
        path: '/competitionManage',
        name: 'competitionManage',
        meta: {
          title: 'competitionManage',
        },
        component: () => import('@/views/competitionCenter/competitionManage.vue'),
      },
      //比赛管理中的创建比赛
      {
        path: '/createManage',
        name: 'createManage',
        meta: {
          title: 'createManage',
        },
        component: () => import('@/views/competitionCenter/components/createManage.vue')
      },
      //比赛管理中的修改比赛
      {
        path: '/changeMatch',
        name: 'changeMatch',
        meta: {
          title: 'changeMatch',
        },
        component: () => import('@/views/competitionCenter/components/changeMatch.vue')
      },
      //比赛管理中的人员审核
      {
        path: '/peopleAudit',
        name: 'peopleAudit',
        meta: {
          title: 'peopleAudit',
        },
        component: () => import('@/views/competitionCenter/components/peopleAudit.vue')
      },
      //比赛管理中的作弊监控
      {
        path: '/cheatMonitoring',
        name: 'cheatMonitoring',
        meta: {
          title: 'cheatMonitoring',
        },
        component: () => import('@/views/competitionCenter/components/cheatMonitoring.vue')
      },
      //比赛管理中的配置试卷
      {
        path: '/configPaper',
        name: 'configPaper',
        meta: {
          title: 'configPaper',
        },
        component: () => import('@/views/competitionCenter/configPaper.vue')
      },
      //阅卷管理
      {
        path: '/markingManage',
        name: 'markingManage',
        meta: {
          title: 'markingManage',
        },
        component: () => import('@/views/competitionCenter/markingManage.vue'),
      },
      //数据管理
      {
        path: '/dataManage',
        name: 'dataManage',
        meta: {
          title: 'dataManage',
        },
        component: () => import('@/views/competitionCenter/dataManage.vue'),
      },
      //团队管理
      {
        path: '/teamManage',
        name: 'teamManage',
        meta: {
          title: 'teamManage',
        },
        component: () => import('@/views/competitionCenter/teamManage.vue'),
      },
      //组织架构
      {
        path: '/orgManage',
        name: 'orgManage',
        meta: {
          title: 'orgManage',
        },
        component: () => import('@/views/systemSet/orgManage.vue'),
      },
      //用户设置
      {
        path: '/userSet',
        name: 'userSet',
        meta: {
          title: 'userSet',
        },
        component: () => import('@/views/systemSet/userSet.vue')
      },
      //角色设置
      {
        path: '/roleSet',
        name: 'roleSet',
        meta: {
          title: 'roleSet',
        },
        component: () => import('@/views/systemSet/roleSet.vue')
      },
      //行为日志
      {
        path: '/log',
        name: 'log',
        meta: {
          title: 'log',
        },
        component: () => import('@/views/systemSet/log.vue')
      },
    ]
  },
  {
    path: '/mainPage',
    name: 'mainPage',
    meta: {
      title: 'mainPage',
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/enRollDetail',
    name: 'enRollDetail',
    meta: {
      title: 'enRollDetail',
    },
    component: () => import('@/views/indexStyle/enRollDetail.vue')
  },
  //数据中心
  {
    path: '/dataSource',
    name: 'dataSource',
    meta: {
      title: 'dataSource',
    },
    component: () => import('@/views/dataCenter/dataSource.vue')
  },

  {
    path: '/billDataQuery',
    name: 'billDataQuery',
    meta: {
      title: 'billDataQuery',
    },
    component: () => import('@/views/dataCenter/billDataQuery.vue')
  },
  {
    path: '/fundDataQuery',
    name: 'fundDataQuery',
    meta: {
      title: 'fundDataQuery',
    },
    component: () => import('@/views/dataCenter/fundDataQuery.vue')
  },
  //通知列表
  {
    path: '/notifacationCenter',
    name: 'notifacationCenter',
    meta: {
      title: 'notifacationCenter',
    },
    component: () => import('@/views/notifacationCenter/notifacationCenter.vue')
  },
  //通知详情
  {
    path: '/notifacationCenterDetail',
    name: 'notifacationCenterDetail',
    meta: {
      title: 'notifacationCenterDetail',
    },
    component: () => import('@/views/notifacationCenter/notifacationCenterDetail.vue')
  },
  //消息中心
  {
    path: '/messageCenter',
    name: 'messageCenter',
    meta: {
      title: 'messageCenter',
    },
    component: () => import('@/views/notifacationCenter/messageCenter.vue')
  },
  // 404
  {
    path: '/*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/404/index.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: "/exam",
  // scrollBehavior:(to,from, savePosition)=>{
  //     if(to.hash) return {selector:to.hash};//跳转到锚点
  //     return savePosition || {x:0,y:0};//回归历史滚动位置
  // },
  routes
});

// router.beforeEach((to, from, next) => {
//   // document.title = to.meta.title || '';//路由发生变化修改页面title
//   next()
// });


export default router
